import Empty from "@shared/empty";
import SubHeader from "@/components/SubHeader";
import PermissionInfoCard from "../components/PermissionInfo";
import permissionsCard from "@shared/components/roles-and-permissions/permissionsCard";
import ListView from "@shared/components/lists";
import axios from "@/axios";
import Loader from "@shared/loader";
import Button from "@shared/components/button";
const ModalConfirm = () => import("@shared/modal-confirm");
const Drawer = () => import("@shared/components/drawer");
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "roles-and-permissions",
    components: {
        SubHeader,
        PermissionInfoCard,
        permissionsCard,
        ListView,
        Loader,
        Button,
        ModalConfirm,
        Empty,
        Drawer,
    },
    props: {},
    title: "Roles and Permissions",
    data: () => ({
        roleInfo: null,
        permissions: [],
        loadingStates: {
            groups: false,
            users: false,
            role: false,
            permission: false,
            permissionsEdit: false,
            deleteRole: false,
            groupEdit: false,
            userEdit: false,
            updateRole: false,
        },
        serchValues: {
            groups: "",
            users: "",
        },
        users: [],
        groups: [],
        tenant_id: null,
        role_id: null,
        currentPermission: null,
    }),

    computed: {
        filterdGroups() {
            return this.groups?.filter((el) => el.name?.toLowerCase()?.includes(this.serchValues.groups.toLowerCase()));
        },
        filterdUsers() {
            return this.users?.filter((el) => el.name?.toLowerCase()?.includes(this.serchValues.users.toLowerCase()));
        },
        getPermissionsList() {
            return this.permissions;
        },
        disableUsers() {
            return this.checkPermission("role.edit") && this.checkPermission("users.read");
        },
        disableGroups() {
            return this.checkPermission("role.edit") && this.checkPermission("groups.read");
        },
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenant_id = this.$store.getters.getTenantId;
        this.role_id = this.$route.params.id;
        await this.getRoleDetails();
        await this.getPermissions();
        await this.fetchUsersList();
        await this.fetchGroupsList();
    },
    methods: {
        checkPermission,
        async fetchUsersList() {
            this.loadingStates.users = true;
            let url = `tenant-role/${this.role_id}/users`;
            let items = null;
            try {
                let { data } = await axios.get(url);
                this.users = data;
                this.serchValues['users'] = '';
            } catch (error) {
                console.log(error, "<<<<error while fetching users");
            }
            this.loadingStates.users = false;
            return items;
        },
        async updateRole() {
            this.loadingStates.updateRole = true;
            let url = `tenant-role/${this.role_id}`;
            try {
                let { name, description } = this.roleInfo;
                let { data } = await axios.put(url, { name, description });
                this.$toast.success(data.message || "Role updated!");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to update!");
            }
            this.loadingStates.updateRole = false;
        },
        async getRoleDetails() {
            this.loadingStates.role = true;
            let url = `tenant-role/${this.role_id}`;
            try {
                let { data } = await axios.get(url);
                this.roleInfo = data;
            } catch (error) {
                console.log(error, "<<<<error while fetching role details");
            }
            this.loadingStates.role = false;
        },
        async fetchGroupsList() {
            this.loadingStates.groups = true;
            let url = `tenant-role/${this.role_id}/groups`;
            let items = null;
            try {
                let { data } = await axios.get(url);
                this.groups = data;
                this.serchValues['groups'] = '';
            } catch (error) {
                console.log(error, "<<<<error while fetching groups");
            }
            this.loadingStates.groups = false;
            return items;
        },
        handleFilterResult(event, type) {
            this.serchValues[type] = event;
        },
        async deleteRole() {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Role  will be deleted permanently. Please confirm to continue deleting this role.",
                buttonText: "Confirm",
            });
            if (promise) {
                this.loadingStates.deleteRole = true;
                try {
                    let { data } = await axios.delete(`tenant-role/${this.role_id}`);
                    this.$router.push({
                        name: "roles-and-permissions-list",
                    });
                    this.$toast.success(data.message || "Role deleted!");
                } catch (error) {
                    console.log(error, ">>>>error");
                    this.$toast.error(error.response.data.detail || "Failed to delete role!");
                }
                this.loadingStates.deleteRole = false;
            }
            this.$refs["confirm-popup"].close();
        },
        async getPermissions() {
            this.loadingStates.permission = true;
            try {
                let { data } = await axios.get(`tenant-role/${this.role_id}/permissions`);
                this.permissions = data;
            } catch (error) {
                console.log(error, ">>>>error");
            }
            this.loadingStates.permission = false;
        },
        async updatePermissions() {
            this.loadingStates.permissionsEdit = true;
            try {
                let { data } = await axios.post(`tenant-role/${this.role_id}/permissions`, this.permissions);
                // this.rows.push({ ...roles_and_permission, id: data.id, permissions_count: 0, users_count: 0, groups_count: 0,  });
                this.$toast.success(data.message || "Role updated!");
            } catch (error) {
                console.log(error, ">>>>error");
                this.$toast.error(error.response.data.detail || "Failed to update role!");
            }
            this.loadingStates.permissionsEdit = false;
        },
        async updateUsers() {
            this.loadingStates.userEdit = true;
            try {
                let { data } = await axios.post(`tenant-role/${this.role_id}/users`, this.users);
                // this.rows.push({ ...roles_and_permission, id: data.id, permissions_count: 0, users_count: 0, groups_count: 0,  });
                this.$toast.success(data.message || "Role updated!");
            } catch (error) {
                console.log(error, ">>>>error");
                this.$toast.error(error.response.data.detail || "Failed to update role!");
            }
            this.loadingStates.userEdit = false;
        },
        async updateGroups() {
            this.loadingStates.groupEdit = true;
            try {
                let { data } = await axios.post(`tenant-role/${this.role_id}/groups`, this.groups);
                // this.rows.push({ ...roles_and_permission, id: data.id, permissions_count: 0, users_count: 0, groups_count: 0,  });
                this.$toast.success(data.message || "Role updated!");
            } catch (error) {
                console.log(error, ">>>>error");
                this.$toast.error(error.response.data.detail || "Failed to update role!");
            }
            this.loadingStates.groupEdit = false;
        },
        selectAllPer({ input, index, all, per }) {
            input = input.target.checked;
            if (all) {
                this.permissions = this.permissions.map((el, id) => {
                    if (id === index) {
                        return { ...el, permissions: el.permissions.map((per) => ({ ...per, selected: input })) };
                    } else {
                        return el;
                    }
                });
                console.log(this.permissions);
            } else {
                this.permissions = this.permissions.map((el) => {
                    let permissions_checked_count = el.permissions.filter((el) => el.selected).length;
                    if (permissions_checked_count === el.permissions.length) {
                        return { ...el, selected: true };
                    } else {
                        return { ...el, selected: false };
                    }
                });
            }
            this.permissionsRowCLick(per);
        },
        selectAll(event, type) {
            this[type] = this[type].map((el) => ({ ...el, selected: event }));
        },
        openDrawer() {
            this.$refs.drawer.open();
        },
        closeDrawer() {
            this.$refs.drawer.close();
        },
        permissionsRowCLick(event) {
            this.currentPermission = null;
            if (event && event.description && event.selected) {
                this.currentPermission = event;
                this.openDrawer();
            }
        },
        viewPermissionsInfo(event) {
            this.currentPermission = null;
            if (event && event.description) {
                this.currentPermission = event;
                this.openDrawer();
            }
        },
    },
};
